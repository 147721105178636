import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import useMedia from 'use-media';
import Image from '../../atoms/Image';
import Badge from '../../atoms/Badge';
import styles from './BannerSmall.module.scss';

const Title = ({ children, isH1, className }) => {
    return isH1 ? (
        <h1 className={className}>{children}</h1>
    ) : (
        <h2 className={className}>{children}</h2>
    );
};

const BannerSmall = ({ banner }) => {
    const isMobile = useMedia({ maxWidth: 640 });

    const { alignment, image, title, is_h1, content, link, text_color } = banner;

    const center = alignment === 'center';
    const left = alignment === 'left';
    const right = alignment === 'right';

    const bannerClass = cx('w-full flex bg-cover bg-no-repeat bg-center relative', styles.banner, {
        'justify-center items-center': center && !isMobile,
        'justify-start items-center': left && !isMobile,
        'justify-end items-center': right && !isMobile,
        'justify-center items-end': isMobile,
    });
    const bannerInnerClass = cx({
        'w-[600px] rounded-xl py-8 px-10': !isMobile,
        'text-center': center,
        'text-left': left,
        'text-right': right,
        'p-6 w-full rounded-tl-xl rounded-tr-xl rounded-b-none bg-opacity-90': isMobile,
    });
    const titleClass = cx('font-heading font-body-normal', {
        'text-h2 mb-2': !isMobile,
        'text-h3 mb-0': isMobile,
    });
    const badgeClass = cx('absolute right-0', {
        'top-0 text-caption': isMobile,
        'bottom-0': !isMobile,
    });

    return (
        <div className={bannerClass} style={{ height: !isMobile ? '150px' : '100px' }}>
            <div className='-z-1'>
                <Image
                    alt={title}
                    src={image}
                    layout='fill'
                    objectFit='cover'
                    objectPosition='center'
                    quality={70}
                />
            </div>

            <div className={bannerInnerClass}>
                <Title
                    className={titleClass}
                    style={{ color: text_color ? text_color : '#000000' }}
                    isH1={is_h1}
                >
                    {title}
                </Title>
                <div
                    style={{ color: text_color ? text_color : '#000000' }}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </div>

            {link.link && (
                <Badge className={badgeClass} type={isMobile ? 'linkTop' : 'linkBottom'}>
                    {link.text}
                </Badge>
            )}
        </div>
    );
};

BannerSmall.defaultProps = {};

BannerSmall.propTypes = {
    banner: PropTypes.object,
};

export default BannerSmall;
