import React from 'react';
import * as PropTypes from 'prop-types';
import BannerSmall from '@/yema-designkit/webkit/molecules/BannerSmall';

const CMS_BannerSmall = ({ content }) => {
    if (!content) return '';

    return <BannerSmall banner={content} />;
};

CMS_BannerSmall.defaultProps = {};

CMS_BannerSmall.propTypes = {
    content: PropTypes.object.isRequired,
};

export default React.memo(CMS_BannerSmall);
