import React from 'react';
import * as PropTypes from 'prop-types';
import Link from '../Link';
import cx from 'classnames';

const Badge = ({ className, bgColor, textColor, type, children, href, style }) => {
    const rootClass = cx(className, 'inline-block font-body', {
        'shadow-down text-small rounded-l-none rounded-r-sm px-2 py-1':
            type === 'generic' || type === 'initial' || type === 'stockout',
        'text-small rounded-xxl px-2 py-1': type === 'round',
        'shadow-down text-caption leading-tight border-solid border border-brand-primary-700 rounded-sm px-1 py-0':
            type === 'discount',
        'shadow-down font-body font-body-normal text-small underline rounded-bl-md px-2 py-1 cursor-pointer':
            type === 'linkTop',
        'shadow-down font-body font-body-normal text-small underline rounded-tl-md px-2 py-1 cursor-pointer':
            type === 'linkBottom',

        'bg-brand-secondary-700': !bgColor && type === 'generic',
        'bg-ui-success': !bgColor && type === 'round',
        'bg-brand-tertiary-500': !bgColor && type === 'initial',
        'bg-ui-gray-300': !bgColor && type === 'stockout',
        'bg-ui-white': !bgColor && type === 'discount',
        [bgColor]: bgColor,

        'text-ui-white':
            !textColor && (type === 'generic' || type === 'initial' || type === 'round'),
        'text-ui-gray-900': !textColor && type === 'stockout',
        'text-brand-primary-700': !textColor && type === 'discount',
        'text-ui-black': !textColor && (type === 'linkTop' || type === 'linkBottom'),
        [`font-body-normal ${textColor}`]: textColor,
    });
    const colorRGB = '#FDF2F1';
    const colorRGBA = colorRGB + '80';

    return (
        <span
            className={rootClass}
            style={{
                ...style,
                backgroundColor: type === 'linkBottom' || type === 'linkTop' ? colorRGBA : '',
            }}
        >
            {type === 'linkBottom' || type === 'linkTop' ? (
                <Link href={href}>{children}</Link>
            ) : (
                <>{children}</>
            )}
        </span>
    );
};

Badge.defaultProps = {
    type: 'generic',
};

Badge.propTypes = {
    className: PropTypes.string,
    textColor: PropTypes.string,
    bgColor: PropTypes.string,
    /** Badge variants: intial, stock-out, generic, discount, etc. */
    type: PropTypes.oneOf([
        'generic',
        'round',
        'initial',
        'stockout',
        'discount',
        'linkTop',
        'linkBottom',
    ]),
    children: PropTypes.string.isRequired,
    href: PropTypes.string,
};

export default Badge;
